import React from "react"
import { injectIntl, Link, intlShape, navigate } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Visibility from "@material-ui/icons/Visibility"

import "../style/global.scss"
import Layout from "../components/Layout"

const API_URL = "http://localhost:8443/"  //  "https://mytaskey-mobile.com:8080/"

const RegisterPage = ({ location, intl, classes }) => {  
  const [error, setError] = React.useState("")
  const [errorEmail, setErrorEmail] = React.useState(false)
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [showPassword, setShowPassword] = React.useState(false)
  const [passwordConfirm, setPasswordConfirm] = React.useState("")
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false)
  const [privacy, setPrivacy] = React.useState(false)
  const [agb, setAGB] = React.useState(false)
  const [isLogging, setIsLoggin] = React.useState(false)

  const handleChangeFirstName = event => {
    setFirstName(event.target.value)
  }

  const handleChangeLastName = event => {
    setLastName(event.target.value)
  }

  const handleChangeEmail = event => {
    setEmail(event.target.value)
  }

  const handleChangePassword = event => {
    setPassword(event.target.value)
  }

  const handleChangePasswordConfirm = event => {
    setPasswordConfirm(event.target.value)
  }

  const handleChangePrivacy = event => {
    setPrivacy(event.target.checked)
  }

  const handleChangeAGB = event => {
    setAGB(event.target.checked)
  }

  const checkIfEmailisAvailable = () => {
    fetch(API_URL + "auth/checkEmail/?email=" + email)
      .then(response => {
        console.log("res", response)
        if (response.status === 200) {
          setErrorEmail(false)
        } else {
          setErrorEmail(true)
        }
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const isEmail = () => {}

  const isFormCompleted = () => {
    return (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      password.length > 7 &&
      password === passwordConfirm &&
      privacy &&
      agb
    )
  }

  const signInWithEmail = async () => {
    if (isFormCompleted()) {
      setIsLoggin(true)
      try {
        let response = await fetch(
          location.search !== "" ? API_URL + "auth/acceptEcard" + location.search: API_URL + "auth/register" ,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify({
              username: email,
              firstName,
              lastName,
              password,
              confirmPassword: passwordConfirm,
              company: false,
              baseUrl: "https://mytaskey.com",
            }),
          }
        )
        if (response.status === 200) {
          navigate("/success")
        }
        setIsLoggin(false)
      } catch {
        setError(true)
        setIsLoggin(false)
      }
    } else {
      setIsLoggin(false)
      setError(true)
    }
  }

  return (
    <Layout location={location}>
      <div class="main-w3layouts wrapper">
        <h1 className={classes.title}>
          {intl.formatMessage({ id: "register-download-app" })}
        </h1>

        <Paper className={classes.paper}>
          <div>
            <div class="agileits-top">
              {!isLogging ? (
                <form action="#" role="form">
                  <div className={classes.formField}>
                    <TextField
                      value={firstName}
                      onChange={handleChangeFirstName}
                      className={classes.field}
                      id="standard-firstName"
                      label={intl.formatMessage({ id: "firstName" })}
                      type="text"
                      variant="standard"
                    />
                  </div>

                  <div className={classes.formField}>
                    <TextField
                      value={lastName}
                      onChange={handleChangeLastName}
                      className={classes.field}
                      id="standard-lastName"
                      label={intl.formatMessage({ id: "lastName" })}
                      type="text"
                      variant="standard"
                    />
                  </div>

                  <div className={classes.formField}>
                    <TextField
                      value={email}
                      onChange={handleChangeEmail}
                      className={classes.field}
                      id="standard-basic"
                      label={intl.formatMessage({ id: "enter-email" })}
                      type="email"
                      variant="standard"
                      onBlur={() => checkIfEmailisAvailable()}
                    />
                    {errorEmail && (
                      <p style={{ color: "red" }}>
                        Diese E-Mail Adresse wurde bereits registriert.
                      </p>
                    )}
                  </div>

                  <div className={classes.formField}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        value={password}
                        onChange={handleChangePassword}
                        className={classes.field}
                        id="standard-password-input"
                        label={intl.formatMessage({ id: "password" })}
                        type={showPassword ? "text" : "password"}
                        variant="standard"
                      />
                      {showPassword ? (
                        <Visibility
                          className={classes.block}
                          color="inherit"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <VisibilityOff
                          onClick={() => setShowPassword(!showPassword)}
                          className={classes.block}
                          color="inherit"
                        />
                      )}
                    </div>
                    {password && password.length < 8 && (
                      <p style={{ color: "red" }}>
                        {intl.formatMessage({
                          id: "must-contain-at-least-char",
                        })}
                      </p>
                    )}
                  </div>

                  <div>
                    {/*  <app-custom-password-strength
            [passwordToCheck]="registerForm.value.password"
          ></app-custom-password-strength> */}
                  </div>

                  <div className={classes.formField}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        value={passwordConfirm}
                        onChange={handleChangePasswordConfirm}
                        className={classes.field}
                        id="standard-password-confirm-input"
                        label={intl.formatMessage({ id: "confirm-password" })}
                        type={showPasswordConfirm ? "text" : "password"}
                        variant="standard"
                      />
                      {showPasswordConfirm ? (
                        <Visibility
                          className={classes.block}
                          color="inherit"
                          onClick={() =>
                            setShowPasswordConfirm(!showPasswordConfirm)
                          }
                        />
                      ) : (
                        <VisibilityOff
                          onClick={() =>
                            setShowPasswordConfirm(!showPasswordConfirm)
                          }
                          className={classes.block}
                          color="inherit"
                        />
                      )}
                    </div>

                    {passwordConfirm &&
                      passwordConfirm.length !== "" &&
                      password !== passwordConfirm && (
                        <p style={{ color: "red" }}>
                          {intl.formatMessage({
                            id: "password-does-not-match",
                          })}
                        </p>
                      )}
                  </div>

                  <div
                    className={classes.formField}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={agb}
                          onChange={handleChangeAGB}
                          name={intl.formatMessage({
                            id: "accept-agb-statement",
                          })}
                          color="primary"
                        />
                      }
                      label={intl.formatMessage({
                        id: "accept-terms-conditions",
                      })}
                    />
                    <a target="_blank" href="https://mytaskey.com/general">
                      AGBs
                    </a>
                  </div>

                  <div
                    className={classes.formField}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={privacy}
                          onChange={handleChangePrivacy}
                          name={intl.formatMessage({
                            id: "accept-privacy-statement",
                          })}
                          color="primary"
                        />
                      }
                      label={intl.formatMessage({
                        id: "accept-privacy-statement",
                      })}
                    />
                    <a target="_blank" href="https://www.mytaskey.com/privacy">
                      Datenschutzerklärung
                    </a>
                  </div>

                  <Button
                    className={classes.field}
                    style={{ width: "100%" }}
                    variant={isFormCompleted() ? "contained" : "outlined"}
                    className={classes.addUser}
                    onClick={signInWithEmail}
                    style={{
                      width: "100%",
                      backgroundColor: isFormCompleted()
                        ? "orange"
                        : "transparent",
                      color: isFormCompleted() ? "white" : "black",
                    }}
                  >
                    {intl.formatMessage({ id: "sign-up" })}
                  </Button>
                </form>
              ) : (
                <div className={classes.containerProgrees}>
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </Paper>
        <p style={{ color: "white", textAlign: "center", marginTop: 10 }}>
          ©2020 mytaskey.com |{" "}
          {intl.formatMessage({ id: "all_rights_reserved" })}.
        </p>
      </div>
    </Layout>
  )
}

RegisterPage.propTypes = {
  location: PropTypes.object,
  intl: intlShape,
}

const styles = theme => ({
  title: {
    color: "white",
    textAlign: "center",
    marginTop: 30,
  },
  paper: {
    maxWidth: 700,
    padding: 10,
    margin: "auto",
    overflow: "hidden",
    marginTop: "5%",
  },
  containerHeader: {
    textAlign: "center",
    //justifyContent: 'center',
  },
  containerRegister: {
    flexDirection: "row",
    justifyContent: "center",
  },
  containerSeparator: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 40,
  },
  separator: {
    height: 2,
    width: "30%",
    backgroundColor: "grey",
    margin: "auto",
  },
  containerForm: {
    paddingTop: 40,
    paddingBottom: 40,
    width: "60%",
    margin: "auto",
  },
  formField: {
    flex: 1,
    width: "100%",
    paddingBottom: 20,
  },
  field: {
    width: "100%",
  },
  containerProgrees: { width: "10%", margin: "auto" },
  errorMsg: { color: "red" },
})

export default withStyles(styles)(injectIntl(RegisterPage))
